import React from 'react';
import './Footer.css';
import { Button } from '../../Button';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import {FaBookMedical} from 'react-icons/fa';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
        Bunzi Medz is SAHPRA Approved.
        </p>

      </section>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>EMAIL ADDRESS</h2>
            <Link to='/'>Bunzimed@gmail.com</Link>

          </div>
          <div className='footer-link-items'>
            <h2>PHYSICAL ADDRESS</h2>
            <Link to='/contact-us'>37 Broberg Street, Brakpan North</Link>

          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>DIRECTOR</h2>
            <Link to='/services'>Maphelo Bunzi</Link>

          </div>

          
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              <FaBookMedical className='navbar-icon' />
              BUNZI MEDZ
            </Link>
          </div>
          <small className='website-rights'>BUNZI MEDZ © 2023</small>

        </div>
      </section>
    </div>
  );
}

export default Footer;
