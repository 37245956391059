export const homeObjOneCon = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'WANT TO GET IN CONTACT? BELOW ARE OUR DETAILS.',
    headline: 'CONTACT INFORMATION',
    description:
      'We provide local shipping. If there are any questions feel free to get in contact with us.',
    buttonLabel: 'Shop Now',
    imgStart: '',
    img: 'images/logo3.jpg',
    alt: 'Credit Card',
    email: 'Bunzimed@gmail.com',
    contactName: 'Maphelo Bunzi',
    contactNumber: '',
    Addressln1: '14 8th Avenue',
    Addressln2: 'Northmead',
    Addressln3: 'Benoni',
  };