export const homeObjOne = {
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Medical Equipment Distribution',
  headline: 'BUNZI MEDZ',
  description:
    'Bunzi Medz is a privately-owned company operating out of Johannesburg, South Africa. We supply a large range of top quality medical products across the country',
  buttonLabel: 'More Info',
  imgStart: 'start',
  img: 'images/logo1.jpg',
  alt: 'Credit Card',
  showButton: true,
  url: '/services'
};

export const homeObjTwo = {
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'PRODUCTS AND SERVICES',
  headline: 'MEDICAL DISTRIBUTION',
  description:
    "Bunzi Medz's product mix currently consists of a variety of different products for all medical and surgical needs, consisting of surgical consumables and specialty products which are used on a daily basis",
  buttonLabel: 'Learn More',
  imgStart: 'start',
  img: 'images/logo2.jpg',
  alt: 'Vault',
  showButton: false
  
};

export const homeObjThree = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Information About Who We Are',
  headline: 'Bunzi Medz',
  description:
    "Bunzi Medz is a company which has recently been founded. Bunzi Medz Distributors' customer base is extensive, throughout Africa. Our main field of expertise lies in the supply of medical goods to institutions and patients as well as the supply of medicine to registered vendors only.",
  buttonLabel: 'Contact Us',
  imgStart: 'end',
  img: 'images/logo5.jpg',
  alt: 'Vault',
  showButton: true,
  url: '/contact-us'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'DATA ANALYTICS',
  headline: 'Every transaction is stored on our secure cloud database',
  description:
    'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: 'images/svg-8.svg',
  alt: 'Vault'
};
