import React from 'react';
import './HeroSection.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function ContactUsPage({
    lightBg,
    topLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    buttonLabel,
    img,
    alt,
    imgStart,
    showButton,
    email,
    contactName,
    contactNumber,
    Addressln1,
    Addressln2,
    Addressln3


}) {
    return (
        <>
            <div
                className={lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}
            >
                <div className='container'>
                    <div
                        className='row home__hero-row'
                        style={{
                            display: 'flex',
                            flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
                        }}
                    >
                        <div className='col'>
                            <div className='home__hero-text-wrapper'>
                                
                                <h1 className={lightText ? 'heading' : 'heading dark'}>
                                    {headline}
                                </h1>
                                <p
                                    className={
                                        lightTextDesc
                                            ? 'home__hero-subtitle'
                                            : 'home__hero-subtitle dark'
                                    }
                                >
                                    {description}

                                    <div className='contact_wrapper'>
                                        <div className='contact_name_wrapper'>
                                            <div className='large_text'>
                                                Contact Person:
                                            </div>
                                            <div className='small_text'>
                                                {contactName}
                                            </div>


                                        </div>
                                        <div className='email_wrapper'>
                                            <div className='large_text'>
                                                Email Address:
                                            </div>
                                            <div className='small_text'>
                                                {email}
                                            </div>


                                        </div>
    
                                        <div className='email_wrapper'>
                                            <div className='large_text'>
                                                Physical Address:
                                            </div>
                                            <div className='small_text'>
                                                <p className='addressln1'>
                                                    {Addressln1}
                                                </p>
                                                <p className='addressln2'>
                                                    {Addressln2}
                                                </p>
                                                <p className='addressln3'>
                                                    {Addressln3}
                                                </p>
                                            </div>
                                        </div>

                                    </div>

                                </p>

                                {showButton && <Link to='/services'>
                                    <Button buttonSize='btn--wide' buttonColor='blue'>
                                        {buttonLabel}

                                    </Button>
                                </Link>}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactUsPage;
